<template>
  <div class="indicator-single-card">
    <div
      :id="`card_${dataset.cardId}`"
      class="cards-container"
      @click="$emit('toDetail', dataset.cardId, 'edit')"
    >
      <div class="corner-marker">
        <img
          :src="
            require(`@/assets/images/bms/${dataset.cardStatus}_status_corner.png`)
          "
          alt=""
        />
      </div>
      <div class="card-image" v-if="dataset.coverPicture">
        <img
          class="card-cover"
          :src="coverImageRender(dataset.coverPicture)"
          alt=""
        />
      </div>
      <div v-else class="card-image">
        <img
          class="card-cover"
          :src="
            require(`../../../../../assets/images/bms/card-image-${dataset.cardType}.png`)
          "
          alt=""
        />
      </div>
      <div class="card-name-line">
        <span class="name-span">
          {{ dataset.cardName }}
        </span>
        <span class="more-span" @click.stop="popoverClick(dataset)">
          <el-popover
            ref="popover"
            popper-class="more-span-popover"
            placement="bottom-start"
            width="400"
            trigger="click"
          >
            <div class="cascader-container">
              <div v-for="item in cascaderOptions" :key="item.key">
                <div
                  v-if="!(item.key === 'setShare' && dataset.canShared === 0)"
                  :class="[
                    'cascader-first-level',
                    item.key === 'delete' && 'first-level-delete',
                    item.disable && 'first-level-disable',
                  ]"
                  @click="firstLevelClick(item)"
                  @mouseenter="(event) => firstLevelEnter(event, item)"
                  @mouseleave="(event) => firstLevelLeave(event, item)"
                >
                  <span>
                    {{ item.label }}
                  </span>
                  <span v-if="item.children">
                    <i class="el-icon-arrow-right"></i>
                  </span>
                  <div class="cascader-second-container">
                    <div
                      class="cascader-second-level"
                      v-for="element in item.children"
                      :key="element.key"
                      :title="element.label"
                      @click="moveToGroupHandler(element)"
                    >
                      {{ element.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-button class="more-button" slot="reference"
              ><img
                class="more-icon"
                src="@/assets/images/bms/more_icon.png"
                alt=""
            /></el-button>
          </el-popover>
        </span>
      </div>
      <div class="bottom-line">
        <span class="name-and-date">
          <span>
            {{ dataset.updateUserName || "System" }}
          </span>
          <span>
            {{ dataset.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </span>
        </span>
        <span class="flex-center">
          <img
            v-if="dataset.isShared === 'Y'"
            src="@/assets/images/bms/is_shared_icon.png"
            alt=""
          />
          <span class="size-span">
            {{ sizeRender(dataset.cardSize) }}
          </span>
        </span>
      </div>
    </div>

    <!-- 重命名弹框 -->
    <el-dialog
      :visible.sync="rename.show"
      :before-close="
        () => {
          rename.show = false;
        }
      "
      width="600px"
      title="重命名"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="rename.form"
        ref="renameForm"
        :rules="rename.formRule"
      >
        <el-form-item label="指标名称" prop="cardName">
          <el-input v-model="rename.form.cardName"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-button-line">
        <el-button @click="rename.show = false">取消</el-button>
        <el-button type="primary" plain @click="renameHandler('PUBLISH')"
          >保存并发布</el-button
        >
        <el-button type="primary" @click="renameHandler('DRAFT')"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改封面 -->
    <el-dialog
      :visible.sync="coverPicture.show"
      :before-close="
        () => {
          coverPicture.show = false;
        }
      "
      append-to-body
      width="704px"
      title="修改封面"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="coverPicture.form"
        :rules="coverPicture.rules"
        label-position="top"
        ref="coverForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="封面图片"
          prop="coverPicture"
          class="auto-height-item"
        >
          <div class="upload-container" v-loading="coverPicture.loading">
            <el-upload
              class="avatar-uploader"
              :action="uploadPath"
              :with-credentials="true"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips-container">
              <div>
                <div>
                  温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。
                </div>
                <!-- <div>图片尺寸212*112</div> -->
              </div>
              <div
                v-show="coverPicture.form.coverPicture"
                class="delete-icon"
                @click="deleteHandler"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="选择封面"
          prop="choicePicture"
          class="auto-height-item"
        >
          <div class="select-container">
            <div
              :class="['single-card', item.selected && `single-card-selected`]"
              v-for="item in coverPicture.defaulList"
              :key="item.key"
              @click="setImgByDefault(item)"
            >
              <img class="example-img" :src="item.icon" alt="" />
              <img
                class="corner-img"
                :src="
                  require(`../../../../../assets/images/bms/example_${
                    item.selected ? 'selected' : 'unselected'
                  }.png`)
                "
                alt=""
              />
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="dialog-button-line">
        <el-button @click="coverPicture.show = false">取消</el-button>
        <el-button type="primary" plain @click="coverChangeHandler('PUBLISH')"
          >保存并发布</el-button
        >
        <el-button type="primary" @click="coverChangeHandler('DRAFT')"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 预览弹框 -->
    <PreviewDialog
      :dataset="previewDialogDatas"
      @close="
        () => {
          previewDialogDatas.show = false;
        }
      "
    />
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import {
  moveToGroup,
  moveToTop,
  cardRename,
  updateCoverPicture,
  deleteCard,
  setCardShareState,
} from "@/api/ruge/bms/indicatorManagement";
import PreviewDialog from "./previewDialog.vue";

export default {
  name: "indicator-single-card",
  components: {
    PreviewDialog,
  },
  props: {
    /**
     *  cardStatus: 发布：PUBLISH 草稿：DRAFT
     *  cardName
     *  lastUpdateDate
     *  updateUserName
     *  cardId
     *  cardSize
     *
     */
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    isFirst: {
      type: Boolean,
    },
    groupList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentGroupId: {
      type: String,
      default() {
        return "-1";
      },
    },
  },
  watch: {
    currentGroupId: {
      handler(val) {
        this.setOptionGroup();
      },
      immediate: true,
    },
    groupList: {
      handler(val) {
        this.setOptionGroup();
      },
      immediate: true,
    },
  },
  data() {
    return {
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      imageUrl: null,
      cascaderOptions: [
        {
          label: "预览",
          key: "preview",
        },
        {
          label: "重命名",
          key: "rename",
        },
        {
          label: "复制",
          key: "copy",
        },
        {
          label: "移到最前",
          key: "toTop",
          disable: this.isFirst,
        },
        {
          label: "发布记录",
          key: "realeaseRecord",
          disable: this.dataset.canShared === 0,
        },
        {
          label: "修改封面",
          key: "changeCover",
        },
        {
          label: this.dataset.isShared === "Y" ? "取消共享" : "设为共享",
          key: "setShare",
        },
        {
          label: "移动至分组",
          key: "moveToGroup",
          children: [
            {
              label: "默认",
              key: "xxx1",
            },
            {
              label: "财务",
              key: "xxx2",
            },
            {
              label: "工程",
              key: "xxx3",
            },
            {
              label: "客服",
              key: "xxx4",
            },
          ],
        },
        {
          label: "删除",
          key: "delete",
        },
      ],
      rename: {
        show: false,
        formRule: {
          cardName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
        form: {
          cardName: null,
        },
      },
      coverPicture: {
        show: false,
        form: {
          coverPicture: null,
        },
        rules: {
          coverPicture: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: "请上传或选择封面",
            },
          ],
        },
        loading: false,
        defaulList: [
          {
            key: 1,
            desc: "bar",
            icon: require("../../../../../assets/images/bms/card_example_1.png"),
            selected: true,
          },
          {
            key: 2,
            desc: "pie",
            icon: require("../../../../../assets/images/bms/card_example_2.png"),
            selected: false,
          },
          {
            key: 3,
            desc: "line",
            icon: require("../../../../../assets/images/bms/card_example_3.png"),
            selected: false,
          },
        ],
      },
      previewDialogDatas: {
        show: false,
        datas: this.dataset,
      },
      releaseRecord: {
        show: false,
      },
    };
  },
  methods: {
    popoverClick({ cardId }) {
      this.$eventBus.$emit("indicator-popover-close", cardId);
    },
    firstLevelEnter(event, datas) {
      $(".cascader-second-container").hide();
      if (!datas.children) return;
      const { pageX } = event;
      const { innerWidth } = window;
      const SafeWidth = 160;
      // left/right
      let classSet = "cascader-";
      classSet += pageX + SafeWidth > innerWidth ? "left" : "right";
      const containerDom = event.target;
      $(containerDom)
        .find(".cascader-second-container")
        .addClass(classSet)
        .show();
    },
    firstLevelLeave(event, datas) {
      if (!datas.children) return;
      const containerDom = $(event.target);
      containerDom
        .find(".cascader-second-container")
        .removeClass("cascader-left cascader-right");
    },
    firstLevelClick(item) {
      if (item.disable) return;
      switch (item.key) {
        case "toTop":
          this.toTopHandler();
          break;
        case "rename":
          this.rename.form.cardName = this.dataset.cardName;
          this.rename.show = true;
          break;
        case "changeCover":
          this.setDefaultImage();
          this.coverPicture.show = true;
          break;
        case "delete":
          this.cardDeleteHandler();
          break;
        case "preview":
          this.previewDialogDatas.show = true;
          break;
        case "setShare":
          this.setShareHandler();
          break;
        case "copy":
          this.$emit("toDetail", this.dataset.cardId, "copy");
          break;
        case "realeaseRecord":
          this.$router.push({
            path: "/bms/indicatorReleaseRecord",
            query: {
              cardId: this.dataset.cardId,
              cardName: this.dataset.cardName,
              layout: "hide",
            },
          });
          break;
      }
      this.popoverClose();
    },
    setShareHandler() {
      const { cardId, isShared } = this.dataset;
      this.$confirm(
        isShared === "Y" ? `是否取消共享？` : "是否设为共享？",
        "提示",
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "info",
        }
      )
        .then(() => {
          setCardShareState({
            cardId,
            isShared: isShared === "Y" ? "N" : "Y",
          }).then(() => {
            this.$message.success("设置成功！");
            this.$emit("close");
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    cardDeleteHandler() {
      this.$confirm(`确认删除该指标吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      })
        .then(() => {
          const { cardId } = this.dataset;
          deleteCard(cardId).then(() => {
            this.$message.success("删除成功！");
            this.$emit("close");
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    renameHandler(cardStatus) {
      // PUBLISH | DRAFT
      this.$refs.renameForm.validate((valid) => {
        if (valid) {
          const { cardId } = this.dataset;
          const cardName = this.rename.form.cardName;
          cardRename({ cardStatus, cardId, cardName })
            .then(() => {
              this.$message.success("保存成功！");
              this.$emit("close");
            })
            .finally(() => {
              this.rename.form.cardName = null;
              this.rename.show = false;
            });
        }
      });
    },
    toTopHandler() {
      const { cardId } = this.dataset;
      moveToTop({
        cardId,
        groupId: this.currentGroupId,
      }).then(() => {
        this.$message.success(`移动成功！`);
        this.$emit("close");
      });
    },
    setOptionGroup() {
      if (!this.currentGroupId || !this.groupList.length) {
        return;
      }
      const tempList = this.groupList.filter(
        (item) => item.groupId !== this.currentGroupId
      );
      this.cascaderOptions.forEach((item) => {
        if (item.key === "moveToGroup") {
          item.children = tempList.map((ele) => {
            return {
              label: ele.groupName,
              key: ele.groupId,
            };
          });
        }
        if (item.key === "setShare") {
          item.label = this.dataset.isShared === "Y" ? "取消共享" : "设为共享";
        }
        if (item.key === "toTop") {
          item.disable = this.isFirst;
        }
        if (item.key === "realeaseRecord") {
          item.disable = this.dataset.canShared === 0;
        }
      });
      this.$forceUpdate();
    },
    moveToGroupHandler({ key, label }) {
      this.popoverClose();
      const { cardName, cardId } = this.dataset;
      moveToGroup({
        cardId,
        groupId: key,
      }).then(() => {
        this.$message.success(`【${cardName}】指标已移动至【${label}】分组`);
        this.$emit("close");
      });
    },
    popoverClose(cardId) {
      if (this.dataset.cardId !== cardId) {
        this.$refs.popover.doClose();
      }
    },
    sizeRender(size) {
      const SizeMap = {
        1: "小",
        2: "中",
        3: "大",
      };
      if (typeof size === "string") {
        try {
          const parsedSize = JSON.parse(size);
          return SizeMap[parsedSize.mode];
        } catch (error) {
          return "小";
        }
      }
    },
    handleAvatarSuccess(files) {
      this.clearAllSelect();
      this.coverPicture.form.coverPicture = files[0].fileId;
      this.imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.coverPicture.loading = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.coverPicture.loading = returnFlag;
      return returnFlag;
    },
    deleteHandler() {
      if ([1, 2, 3].includes(this.coverPicture.form.coverPicture)) {
        this.clearAllSelect();
      }
      this.coverPicture.form.coverPicture = null;
      this.imageUrl = null;
    },
    setImgByDefault(item) {
      this.clearAllSelect(item.key);
      this.imageUrl = item.icon;
      this.coverPicture.form.coverPicture = item.key;
    },
    clearAllSelect(activeKey) {
      this.coverPicture.defaulList.forEach((ele) => {
        ele.selected = ele.key === activeKey;
      });
    },
    coverChangeHandler(cardStatus) {
      // PUBLISH | DRAFT
      this.$refs.coverForm.validate((valid) => {
        if (valid) {
          const { cardId } = this.dataset;
          const coverPicture = this.coverPicture.form.coverPicture;
          updateCoverPicture({ cardStatus, cardId, coverPicture })
            .then(() => {
              this.$message.success("保存成功！");
              this.$emit("close");
            })
            .finally(() => {
              this.coverPicture.form.coverPicture = null;
              this.coverPicture.show = false;
            });
        }
      });
    },
    coverImageRender(fileId) {
      if ([1, 2, 3].includes(fileId)) {
        return require(`../../../../../assets/images/bms/card_example_${fileId}.png`);
      } else {
        return `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${fileId}`;
      }
    },
    setDefaultImage() {
      /**
       * 三种情况：
       *  13431 - 上传图片的ID
       *  1     - 选择的默认图片
       *  null  - 没有上传过
       */
      const coverMap = {
        bar: 1,
        pie: 2,
        line: 3,
      };
      // if (this.dataset.coverPicture) {
      //   // 有设置cover
      //   this.imageUrl = this.coverImageRender(this.dataset.coverPicture);
      this.clearAllSelect();
      // } else {
      const current =
        this.dataset.coverPicture || coverMap[this.dataset.cardType];
      this.imageUrl = this.coverImageRender(current);
      this.clearAllSelect(current);
      // }
      this.coverPicture.form.coverPicture =
        coverMap[this.dataset.coverPicture] ||
        this.dataset.coverPicture ||
        coverMap[this.dataset.cardType];
    },
  },
};
</script>

<style lang="less" scoped>
.indicator-single-card {
  .cards-container {
    cursor: pointer;
    height: 260px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(142, 142, 142, 0.1);
    border-radius: 10px;
    border: 1px solid #eef2f6;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .corner-marker {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .card-image {
    margin: 10px;
    height: 170px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    &:hover {
      .card-cover {
        transform: scale(1.2);
      }
    }
    .card-cover {
      width: 100%;
      height: 100%;
      transition: all 0.8s;
    }
  }
  .card-image-line {
    background-image: url("../../../../../assets/images/bms/card-image-line.png");
  }
  .card-image-bar {
    background-image: url("../../../../../assets/images/bms/card-image-bar.png");
  }
  .card-image-pie {
    background-image: url("../../../../../assets/images/bms/card-image-pie.png");
  }
  .card-name-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .name-span {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #252d3d;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .more-span {
      display: inline-block;
      width: 28px;
      text-align: right;
      border-radius: 4px;
      .more-button {
        padding: 0;
        width: 28px;
        border: none;
      }
      .more-icon {
        cursor: pointer;
      }
      &:hover {
        background: #f5f5f6;
      }
    }
  }
  .bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .name-and-date {
      font-weight: 400;
      font-size: 12px;
      color: #a7b4c7;
      span + span {
        margin-left: 8px;
      }
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .size-span {
      display: inline-block;
      width: 28px;
      height: 16px;
      line-height: 16px;
      background: #2a61ff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      margin-left: 6px;
    }
  }
}
</style>
<style lang="less">
.upload-container {
  display: flex;
  .tips-container {
    margin-left: 15px;
    font-size: 12px;
    & > div {
      height: 20px;
      line-height: 20px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
}
.avatar-uploader {
  height: 177px;
  width: 300px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 177px;
  width: 300px;
  line-height: 177px;
  text-align: center;
}
.avatar {
  height: 177px;
  width: 300px;
  display: block;
}
.select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .single-card {
    width: 200px;
    height: 118px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #eef2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    box-sizing: content-box;
    .example-img {
      width: 200px;
      height: 118px;
      border-radius: 10px;
    }
    .corner-img {
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      border-color: #2a61ff;
    }
  }
  .single-card-selected {
    border: 1px solid #2a61ff;
  }
}
.more-span-popover {
  width: 117px !important;
  padding: 0;
  .cascader-container {
    .cascader-first-level {
      font-weight: 500;
      font-size: 14px;
      color: #152c5b;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        color: #2a61ff;
        background: #f5f5f6;
        .cascader-second-container {
          display: block;
        }
      }
      .cascader-second-container {
        display: none;
        position: absolute;
        background: #ffffff;
        width: 148px;
        max-height: 270px;
        overflow: auto;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .cascader-second-level {
          font-weight: 500;
          font-size: 14px;
          color: #152c5b;
          height: 30px;
          line-height: 30px;
          padding: 0 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            background: #f5f5f6;
          }
        }
      }
      .cascader-left {
        bottom: -100%;
        left: -100%;
      }
      .cascader-right {
        bottom: -100%;
        right: -100%;
      }
    }
    .first-level-disable {
      color: #adadad;
      cursor: not-allowed;
      &:hover {
        color: #adadad;
      }
    }
    .first-level-delete {
      color: #ff0000;
      &:hover {
        color: #ff0000;
        background: #f5f5f6;
      }
    }
  }
}
</style>