<template>
  <div
    class="app-container indicator-management-list"
    v-loading="loading"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
  >
    <div class="header-line">
      <i
        v-if="showSider"
        class="el-icon-arrow-left arrow-icon"
        @click="setTransformX('left')"
      ></i>
      <div class="tabs-part">
        <div
          class="tabs-container"
          :style="{
            transform: `translateX(${translateX}px)`,
          }"
        >
          <span
            :class="['single-tab', item.active && 'single-tab-active']"
            v-for="item in groupList"
            :key="item.groupId"
            @click="setTabActive(item)"
            :title="item.groupName"
          >
            {{ item.groupName }}
          </span>
        </div>
      </div>
      <i
        v-if="showSider"
        class="el-icon-arrow-right arrow-icon"
        @click="setTransformX('right')"
      ></i>
      <div class="dropdown-part">
        <el-dropdown @command="handleCommand">
          <el-button
            type="primary"
            style="background: #2a61ff; border-radius: 8px"
          >
            操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="newIndicator">新增指标</el-dropdown-item>
            <el-dropdown-item command="groupManagement"
              >分组管理</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="card-list" v-on:scroll="containerScroll">
      <div
        class="group-container"
        :ref="`group_${item.groupId}`"
        :id="`group_${item.groupId}`"
        v-for="item in groupList"
        :key="item.groupId"
      >
        <div class="group-label">
          {{ item.groupName }}
        </div>
        <div class="no-card" v-if="!item.cardList.length">暂无数据</div>
        <div class="card-container" v-else>
          <el-row :gutter="30" style="width: 100%">
            <el-col
              :span="8"
              v-for="(item, index) in item.cardList"
              :key="item.cardId"
            >
              <SingleCard
                :dataset="item"
                :isFirst="index === 0"
                :currentGroupId="item.groupId"
                :groupList="groupListMap"
                @close="initDatas"
                @toDetail="jumpToDetail"
                ref="singleCardRef"
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <GroupManageDialog
      v-if="groupManageDialog.show"
      :dataset="groupManageDialog"
      @close="GroupManageDialogClose"
    />
  </div>
</template>

<script>
import GroupManageDialog from "./components/groupManageDialog.vue";
import SingleCard from "./components/singleCard.vue";
import { getGroupAndCards } from "@/api/ruge/bms/indicatorManagement";
import { mapGetters } from "vuex";

export default {
  name: "indicator-management-list",
  components: {
    GroupManageDialog,
    SingleCard,
  },
  data() {
    return {
      loading: false,
      groupList: [],
      groupListMap: [],
      groupManageDialog: {
        show: false,
      },
      showSider: false,
      translateX: 0,
      tabsDiff: 0,
      scrollLock: false,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  mounted() {
    this.initDatas();
    this.$eventBus.$off("indicator-popover-close");
    this.$eventBus.$on("indicator-popover-close", (cardId) => {
      this.clearPopover(cardId);
    });
  },
  methods: {
    setTransformX(direction) {
      // const oneStepWidth =
      //   document.getElementsByClassName("single-tab")[0].offsetWidth;
      if (direction === "left") {
        if (this.translateX >= 0) return;
        // this.translateX += oneStepWidth * 4;
        this.translateX += 200;
      } else {
        if (this.translateX <= -this.tabsDiff) return;
        // this.translateX -= oneStepWidth * 4;
        this.translateX -= 200;
      }
    },
    calcTabsProps() {
      // tabs-part 获取tabs的容器的宽度
      // offsetWidth: 获取元素的宽度，包括边框和内边距
      // clientWidth: 获取元素的宽度，不包括边框和内边距，但包括滚动条
      const outerContainer = document.getElementsByClassName("tabs-part")[0];
      const innerContainer =
        document.getElementsByClassName("tabs-container")[0];
      this.showSider = innerContainer.offsetWidth > outerContainer.offsetWidth;
      this.tabsDiff = innerContainer.offsetWidth - outerContainer.offsetWidth;
    },
    clearPopover(cardId) {
      if (this.$refs.singleCardRef && this.$refs.singleCardRef.length) {
        this.$refs.singleCardRef.forEach((ins) => {
          ins.popoverClose(cardId);
        });
      }
    },
    containerScroll() {
      this.clearPopover();
      if (this.scrollLock) return;
      const containerDom = document.getElementsByClassName("card-list")[0];
      const offsetTop = containerDom.scrollTop;
      if (
        containerDom.scrollTop + containerDom.clientHeight ==
        containerDom.scrollHeight
      ) {
        this.setActiveByIndex(this.groupList.length - 1);
        return;
      }
      // if (offsetTop < containerDom.offsetHeight - 50) {
      //   setActiveByIndex(this.dimensionList.length);
      //   return;
      // }
      // 计算子元素高度区间
      // [248,664,336]
      const heightList = [];
      this.groupList.forEach((item, index) => {
        // const currentItem = this.$refs[`group_${item.groupId}`][0].$el;
        const currentItem = document.getElementById(`group_${item.groupId}`);
        const currentHeight = currentItem.offsetHeight;
        const preHeight = heightList[index - 1] || 0;
        heightList.push(currentHeight + preHeight);
      });
      for (let i = 0; i < heightList.length; i++) {
        if (offsetTop <= heightList[i]) {
          this.setActiveByIndex(i);
          break;
        }
      }
    },
    setTabActive({ groupId }) {
      this.scrollLock = true;
      let activeIndex = 0;
      this.groupList.forEach((item, index) => {
        if (item.groupId === groupId) {
          this.setScrollTopByGroupId(item.groupId);
          activeIndex = index;
        }
        item.active = item.groupId === groupId;
      });
      setTimeout(() => {
        this.scrollLock = false;
        this.scrollTabIntoView(activeIndex);
      }, 100);
    },
    jumpToDetail(cardId, type) {
      this.$router.push({
        path: "/bms/indicatorEdit",
        query: {
          cardId,
          layout: "hide",
          type,
        },
      });
    },
    handleCommand(command) {
      switch (command) {
        case "groupManagement":
          this.groupManageDialog.show = true;
          break;
        case "newIndicator":
          this.$router.push({
            path: "/bms/indicatorEdit",
            query: {
              layout: "hide",
              groupId: this.groupList.filter((item) => item.active)[0].groupId,
            },
          });
          break;
      }
    },
    GroupManageDialogClose(freshFlag) {
      this.groupManageDialog.show = false;
      freshFlag && this.initDatas();
    },
    initDatas() {
      this.loading = true;
      getGroupAndCards()
        .then((res) => {
          this.groupList = res.map((item, index) => {
            item.active = index === 0;
            return item;
          });
          this.groupListMap = res.map((item) => {
            return {
              groupName: item.groupName,
              groupId: item.groupId,
            };
          });
        })
        .finally(() => {
          this.loading = false;
          const { cardId } = this.$route.query;
          if (cardId) {
            this.setScrollTopById(cardId);
          }
          this.$nextTick(() => {
            this.calcTabsProps();
          });
        });
    },
    setScrollTopById(cardId) {
      this.$nextTick(() => {
        try {
          const targetDom = document.getElementById(`card_${cardId}`);
          targetDom.scrollIntoView({});
          // 移除查询参数 cardId
          const newQuery = { ...this.$route.query };
          delete newQuery.cardId;
          this.$router.replace({ query: newQuery });
          // 边框高亮
          targetDom.style.border = "1px solid #1A4CEC";
          setTimeout(() => {
            targetDom.style.border = `1px solid #eef2f6`;
          }, 2000);
        } catch (error) {
          console.log("定位卡片失败：", error);
        }
      });
    },
    setScrollTopByGroupId(groupId) {
      try {
        const targetDom = document.getElementById(`group_${groupId}`);
        targetDom.scrollIntoView({});
      } catch (error) {
        console.log("定位卡片失败：", error);
      }
    },
    setActiveByIndex(setIndex) {
      this.groupList.forEach((item, index) => {
        item.active = setIndex === index;
      });
      this.scrollTabIntoView(setIndex);
    },
    scrollTabIntoView(activeIndex) {
      const oneTabWidth = 120;
      // document.getElementsByClassName("single-tab")[0].offsetWidth;
      const containerWidth =
        document.getElementsByClassName("tabs-part")[0].offsetWidth;
      // 记录中间数
      const middleNumber = (containerWidth / oneTabWidth / 2).toFixed(0);
      if (activeIndex > middleNumber) {
        if (this.translateX <= -this.tabsDiff) return;
        this.translateX = 0 - oneTabWidth * (activeIndex - middleNumber);
      } else {
        this.translateX = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.indicator-management-list {
  // 解决用scrollIntoView父容器会滑动的问题
  position: fixed;
  width: calc(100vw - 330px);
  .header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #f1f4f6;
    .arrow-icon {
      font-size: 20px;
      cursor: pointer;
      position: relative;
      top: -5px;
    }
    .tabs-part {
      width: calc(100% - 180px);
      overflow: hidden;
      position: relative;
      height: 37px;
      .tabs-container {
        transition: transform 0.2s;
        width: max-content;
        .single-tab {
          display: inline-block;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          color: #5d687c;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          height: 37px;
          line-height: 25px;
          cursor: pointer;
          margin-right: 20px;
        }
        .single-tab-active {
          color: #252d3d;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 2px;
            background: #2a61ff;
            border-radius: 7px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 10px);
          }
        }
      }
    }
    .dropdown-part {
      text-align: right;
      position: relative;
      top: -5px;
    }
  }
  .card-list {
    height: calc(100% - 60px);
    overflow: auto;
    .group-container {
      .group-label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #252d3d;
        margin-bottom: 16px;
        position: relative;
        padding: 5px 18px;
        background: linear-gradient(90deg, #eff3f7 0%, #ffffff 100%);
        border-radius: 2px;
        &::before {
          content: "";
          display: inline-block;
          width: 2px;
          height: 12px;
          background: #2a61ff;
          position: absolute;
          left: 9px;
          top: 6px;
        }
      }
      .no-card {
        font-weight: 400;
        font-size: 14px;
        color: #b5bece;
        margin: 10px 0 30px;
      }
    }
  }
}
</style>